import { useQuery } from 'react-query';

import { axiosInstance } from '../../../axios';

const QUERY_KEY = 'FEATURES_SETTINGS_CONFIG';

export const useFetchFeaturesConfig = () => {
  return useQuery(QUERY_KEY, fetcher, {
    select: (data) => data.data.feature_groups,
    staleTime: Infinity,
  });
};

const fetcher = async () =>
  await axiosInstance.get<ResponseType>(`/organizations/features/config`);

type ResponseType = {
  feature_groups: FeatureGroup[];
};

export type FeatureGroup = {
  id: string;
  name: string;
  description: string;
  features: Feature[];
};

export type Feature = {
  id: string;
  name: string;
  parents?: Feature[];
  childs?: Feature[];
};
