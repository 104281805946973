import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { CustomTooltip } from '../common/ToolTip';
import { useFetchOrganization } from '../Users/useFetchOrganization';
import { useConfigurationContext } from './ConfigurationProvider';

type RouteParam = { organizationId: string };

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    backgroundColor: theme.palette.grey[50],
    padding: '24px',
    borderRadius: '16px',
    boxShadow:
      '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);',
  },
  infoWrapper: {
    display: 'flex',
    gap: '24px',
    alignItems: 'center',
  },
  inlineText: {
    padding: '0 8px',
  },
}));

export const OrganizationInfo = () => {
  const classes = useStyles();
  const { organizationId } = useParams<RouteParam>();
  const {
    data: organization,
    isLoading,
    isError,
  } = useFetchOrganization(parseInt(organizationId, 10));
  const { state, dispatch } = useConfigurationContext();

  React.useEffect(() => {
    if (organization) {
      dispatch({
        type: 'SET_ORGANIZATION_INFO',
        payload: {
          info: organization,
        },
      });
    }
  }, [organization, dispatch]);

  if (isError) {
    toast.error('Error in fetching organization details');
  }

  return (
    <Box className={classes.mainWrapper}>
      {state.info && !isLoading ? (
        <Box>
          <Box>
            <Typography variant='h5'>Organization Info</Typography>
          </Box>
          <Box className={classes.infoWrapper}>
            <Typography variant='h6'>
              Name:
              <Typography
                variant='body1'
                display='inline'
                classes={{ root: classes.inlineText }}
              >
                {state.info.name}
              </Typography>
              | Domain:
              <Typography
                variant='body1'
                display='inline'
                classes={{ root: classes.inlineText }}
              >
                {state.info.domain}
              </Typography>
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.info.allow_other_domain_users}
                  onChange={() =>
                    dispatch({
                      type: 'TOGGLE_INFO_SETTINGS',
                      payload: { settingId: 'allow_other_domain_users' },
                    })
                  }
                  color='primary'
                />
              }
              label='Allow other domain users'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.info.auto_add_domain_users}
                  onChange={() =>
                    dispatch({
                      type: 'TOGGLE_INFO_SETTINGS',
                      payload: { settingId: 'auto_add_domain_users' },
                    })
                  }
                  color='primary'
                />
              }
              label='Auto add domain users'
            />
            <CustomTooltip
              tooltipTitle="'Shared Feeds' must be enabled to activate this option."
              show={!state.features?.group_feeds}
            >
              <FormControlLabel
                label='Allow sharing outside org'
                control={
                  <Checkbox
                    size='small'
                    color='primary'
                    checked={
                      state.info ? state.info.allow_sharing_outside_org : false
                    }
                    disabled={!state.features?.group_feeds}
                    onChange={() =>
                      dispatch({
                        type: 'TOGGLE_INFO_SETTINGS',
                        payload: {
                          settingId: 'allow_sharing_outside_org',
                        },
                      })
                    }
                  />
                }
              />
            </CustomTooltip>
          </Box>
        </Box>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};
