import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.grey[900],
    fontSize: '0.675rem',
    padding: theme.spacing(1),
  },
}));

export const CustomTooltip = ({
  tooltipTitle,
  show = true,
  placement = 'top',
  children,
}: CustomTooltipProps) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={tooltipTitle}
      arrow
      disableHoverListener={!show}
      classes={{ tooltip: classes.tooltip }}
      placement={placement}
    >
      <div>{children}</div>
    </Tooltip>
  );
};

type CustomTooltipProps = {
  tooltipTitle: string;
  show?: boolean;
  placement?: 'bottom' | 'top' | 'left' | 'right';
  children: React.ReactNode;
};
