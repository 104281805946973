import { Box, makeStyles, Typography } from '@material-ui/core';
import { Link } from '@mui/icons-material';
import * as React from 'react';

import { Switch } from '../Atoms/Switch';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { CustomTooltip } from '../common/ToolTip';
import { useConfigurationContext } from './ConfigurationProvider';
import { Feature } from './hooks/useFetchFeaturesConfig';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    paddingTop: '4px',
    paddingBottom: '4px',
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 'none',
    },
    '&:first-child': {
      paddingTop: 'none',
    },
  },
  toggleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  icon: {
    color: theme.palette.primary.main,
    rotate: '-45deg',
  },
}));

export const FeatureToggle = ({
  name,
  disabled,
  id,
  additionalContent,
  parents,
  childs,
}: FeatureToggleType) => {
  const classes = useStyles();
  const { state, dispatch } = useConfigurationContext();

  const [modalOpen, setModalOpen] = React.useState(false);

  const enableFeatureAndItsParents = React.useCallback(() => {
    dispatch({ type: 'TOGGLE_FEATURE', payload: { featureId: id } });

    parents
      ?.filter((parent) => !state.features[parent.id])
      .forEach((parent) => {
        dispatch({ type: 'TOGGLE_FEATURE', payload: { featureId: parent.id } });
      });
    setModalOpen(false);
  }, [dispatch, id, parents, state.features]);

  const toggleFeature = React.useCallback(
    (finalValue: boolean) => {
      if (finalValue) {
        if (parents?.some((parent) => !state.features[parent.id])) {
          setModalOpen(true);
          return;
        }
        dispatch({ type: 'TOGGLE_FEATURE', payload: { featureId: id } });

        return;
      }

      dispatch({ type: 'TOGGLE_FEATURE', payload: { featureId: id } });

      childs?.forEach((child) => {
        if (state.features[child.id]) {
          dispatch({
            type: 'TOGGLE_FEATURE',
            payload: { featureId: child.id },
          });
        }
      });
    },
    [childs, dispatch, id, parents, state.features]
  );

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.toggleContainer}>
        <Box className={classes.titleContainer}>
          <Typography variant='body1'>{name}</Typography>
          {parents && parents.length > 0 && (
            <CustomTooltip
              tooltipTitle={
                'Requires ' +
                parents.map((parent) => parent.name).join(' & ') +
                ' to be enabled'
              }
            >
              <Link fontSize='small' className={classes.icon} />
            </CustomTooltip>
          )}
        </Box>

        <Switch
          disabled={disabled}
          checked={state.features ? state.features[id] : false}
          onChange={(_, checked) => {
            toggleFeature(checked);
          }}
        />
      </Box>
      {additionalContent ? additionalContent : null}
      <ConfirmationDialog
        show={modalOpen}
        title='Enable Parent Feature(s)?'
        description='This feature relies on a parent feature(s). Enabling it will also turn on the parent feature(s).'
        closeDialog={() => setModalOpen(false)}
        actionHandler={enableFeatureAndItsParents}
        primaryButtonText='Enable'
        secondaryButtonText='Cancel'
        maxWidth='sm'
      />
    </Box>
  );
};

type FeatureToggleType = Feature & {
  disabled?: boolean;
  additionalContent?: React.ReactNode;
};
