import { ReactComponent as AssistantTypes } from '../../assets/assistant_types.svg';
import { ReactComponent as Collaboration } from '../../assets/collaboration.svg';
import { ReactComponent as ConnectedSources } from '../../assets/connected_sources.svg';
import { ReactComponent as CoreFeatures } from '../../assets/core_features.svg';
import { ReactComponent as DataActions } from '../../assets/data_actions.svg';
import { ReactComponent as DataInsights } from '../../assets/data_insights.svg';
import { ReactComponent as DataManagement } from '../../assets/data_management.svg';
import { ReactComponent as DisplayOptions } from '../../assets/display_options.svg';
import { ReactComponent as SupportAndInformation } from '../../assets/information.svg';
import { ReactComponent as Managers } from '../../assets/managers.svg';
import { ReactComponent as NotificationAndAlerts } from '../../assets/notification.svg';
import { ReactComponent as Others } from '../../assets/others.svg';

const FEATURE_GROUPS = {
  CORE_FEATURES: 'core_features',
  CONNECTED_SOURCES: 'connected_sources',
  DATA_MANAGEMENT: 'data_management',
  COLLABORATION: 'collaboration',
  ASSISTANT_TYPES: 'assistant_types',
  DATA_INSIGHTS: 'data_insights',
  DATA_ACTIONS: 'data_actions',
  OTHERS: 'others',
  DISPLAY_OPTIONS: 'display_options',
  MANAGERS: 'managers',
  NOTIFICATION_AND_ALERTS: 'notification_and_alerts',
  SUPPORT_AND_INFORMATION: 'support_and_information',
};

export const getFeatureGroupIcons = (featureGroupId: string) => {
  switch (featureGroupId) {
    case FEATURE_GROUPS.CORE_FEATURES:
      return CoreFeatures;
    case FEATURE_GROUPS.DATA_MANAGEMENT:
      return DataManagement;
    case FEATURE_GROUPS.COLLABORATION:
      return Collaboration;
    case FEATURE_GROUPS.CONNECTED_SOURCES:
      return ConnectedSources;
    case FEATURE_GROUPS.ASSISTANT_TYPES:
      return AssistantTypes;
    case FEATURE_GROUPS.DATA_INSIGHTS:
      return DataInsights;
    case FEATURE_GROUPS.DATA_ACTIONS:
      return DataActions;
    case FEATURE_GROUPS.DISPLAY_OPTIONS:
      return DisplayOptions;
    case FEATURE_GROUPS.MANAGERS:
      return Managers;
    case FEATURE_GROUPS.NOTIFICATION_AND_ALERTS:
      return NotificationAndAlerts;
    case FEATURE_GROUPS.SUPPORT_AND_INFORMATION:
      return SupportAndInformation;
    default:
      return Others;
  }
};
